
$(function() {
    /* hook up click on contact box */
    $('.contact-box').on('click', function(e) {
        window.location = $('#contact_url').val();
    });

	$('#mobile_challenges_carousel').carousel({interval: 16000, wrap: true});

    $('.carousel-control').on('click', function(e) {
        e.preventDefault();
        /*$($(this).attr('data-target')).carousel('pause');*/
        $($(this).attr('data-target')).carousel('next');
    });

    /* generic ajax form handler */
    $('form.ajax #submit').on('click', function(event) {
        event.preventDefault();
        var $form = $(this).closest('form');
        $.post($form.attr('action'), $form.serialize(), function(data) {
            console.log(data);
            var content = data;
            if ($('#' + $form.attr('id') + '_success')) {
                content = $('#' + $form.attr('id') + '_success').html();
            }
            console.log(content);
            $('#' + $form.attr('data-target')).html(content);
        });
    });

});
